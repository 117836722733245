<template>
  <v-container fluid>
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          SUBIENDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <v-col
        style="text-align: right"
        cols="12"
        md="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Información sobre producción
            </div>
            <div class="text-subtitle-1 font-weight-light">
              {{ dataUsuario.nombre }}, actualice la información sobre la producción de los juzgados de su corte
            </div>
          </template>
          <br>
          <v-row justify="center">
            <v-col
              cols="12"
              md="4"
            >
              <v-file-input
                v-model="archivoCsv2"
                :rules="reglaUpload"
                show-size
                placeholder="Adjunte el archivo de metas"
                prepend-icon="mdi-microsoft-excel"
                clear-icon
                label="Adjunte el archivo de producción"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="text-align: center"
            >
              <v-btn
                tile
                color="success"
                class="ma-2"
                @click="updateResumen(archivoCsv2)"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Subir
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="mesConsulta"
                show-size
                prepend-icon="mdi-calendar-month"
                label="Mes y año"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="text-align: center"
            >
              <v-btn
                color="primary"
                class="ma-2"
                @click="getProduccionSij(1)"
              >
                ACTUALIZAR  BD
                <v-icon right>
                  mdi-home-circle
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="text-align: center"
            >
              <v-btn
                color="green"
                class="ma-2"
                @click="getProduccionSij(2)"
              >
                DESCARGAR EXCEL
                <v-icon right>
                  mdi-home-circle
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- v-row
            align="center"
            justify="center"
          >
            <v-btn
              color="primary"
              depressed
              medium
              @click="$router.push('/editar_menu')"
            >
              Editar/Inicializar menú principal
              <v-icon right>
                mdi-home-circle
              </v-icon>
            </v-btn>
          </v-row> -->
          <br>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import XLSX from 'xlsx'
  export default {
    name: 'Upload',
    data: () => ({
      reglaUpload: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          'El archivo no debe pesar más de 5 MB',
      ],
      usuario: '',
      nombreUsuario: '',
      mesUpdate: '',
      sedeCorteData: '',
      carga: false,
      archivoCsv: [],
      archivoCsv2: [],
      archivoCsv3: [],
      dataUpdate: [],
      urlDataSede: '',
      sumaI: 0,
      incorrectos: 0,
      urlLogo: '',
      mesConsulta: '',
      arrayFinal: [],
      itemsXls: [],
      dataConsulta: [],
      dataUsuario: [],
      dataEnviar: [],
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario || parseInt(this.dataUsuario.es_admin) !== 1) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', false)
      this.$store.commit('SET_LOGO', true)
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      cerrarSesion () {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/login')
      },
      /* leerDataSede () {
        axios
          .get(
            this.urlDataSede,
          )
          .then((response) => {
            this.dataSede = response.data.array.filter(item => item.id === this.sedeCorteData)
            this.urlLogo = 'assets/' + this.dataSede[0].logo
            if (this.urlLogo === 'assets/') { this.urlLogo = 'assets/pj-logo.svg' }
            // this.dataSede = Object.values(response.data).filter(item => item.id === this.sedeCorteData)
            // console.log(Object.values(response.data))
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      }, */
      getProduccionSij (accion) {
        if (this.mesConsulta && this.mesConsulta.split('-').length === 2 && (parseInt(this.mesConsulta.split('-')[0]) < 13 && parseInt(this.mesConsulta.split('-')[0]) > 0) && (parseInt(this.mesConsulta.split('-')[1]) < 2040 && parseInt(this.mesConsulta.split('-')[1]) > 2010)) {
          this.carga = true
          const data = {
            n_anio_est: this.mesConsulta.split('-')[1],
            n_mes_est: this.mesConsulta.split('-')[0],
          }
          axios
            .post(`${window.__env.dataCfg.urlApiSIJWS}produccion/produccionMensual`, data)
            .then((r) => {
              var arreglo = r.data.data
              const lodash = require('lodash')
              const groupByInsta = lodash.groupBy(arreglo, prod => prod.n_dependencia)
              /*  const groupByInstaVar = lodash.forEach(groupByInsta, element => {
                groupByInsta[element.n_dependencia].push({name: })
              }) */
              var finalArray = []
              lodash.forEach(groupByInsta, element => {
                const arrayInside = lodash.groupBy(element, prod => prod.var_des)
                const juzgadoEnviar = { ano: element[0].ANO, nombre: element[0].x_nom_instancia, apela_confirmada: arrayInside.CONFIRMADA ? arrayInside.CONFIRMADA.length : 0, apela_revocada: arrayInside.REVOCADA ? arrayInside.REVOCADA.length : 0, apela_anulada: arrayInside.ANULADA ? arrayInside.ANULADA.length : 0, auto: parseInt(arrayInside['AUTO DE SOBRESEIMIENTO'] ? arrayInside['AUTO DE SOBRESEIMIENTO'].length : 0) + parseInt(arrayInside['OTROS AUTOS FINALES'] ? arrayInside['OTROS AUTOS FINALES'].length : 0) + parseInt(arrayInside['AUTO DE ENJUICIAMIENTO'] ? arrayInside['AUTO DE ENJUICIAMIENTO'].length : 0), conciliacion: arrayInside.CONCILIADOS ? arrayInside.CONCILIADOS.length : 0, demanda_improcedente: arrayInside['DEMANDA IMPROCEDENTE/ DENUNCIA NO HA LUGAR RESUELTA'] ? arrayInside['DEMANDA IMPROCEDENTE/ DENUNCIA NO HA LUGAR RESUELTA'].length : 0, id: element[0].n_dependencia, mes: element[0].MES, sentencia: arrayInside.SENTENCIAS ? arrayInside.SENTENCIAS.length : 0 }
                finalArray.push(juzgadoEnviar)
              })
              this.dataConsulta = finalArray
              if (accion === 1) {
                finalArray.forEach(e => {
                  this.updateProduccion(e, finalArray.length)
                })
              } else {
                this.generarExcel()
              }
            })
            .catch((err) => {
              this.carga = false
              alert('Servicio no disponible' + err)
            })
        } else {
          alert('Ingrese mes y año válido')
        }
      },
      groupBy (keys) {
        return function (array) {
          return array.reduce(function (objectsByKeyValue, obj) {
            var value = keys.map(function (key) { return (obj[key]) }).join('-')
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
            return objectsByKeyValue
          }, {})
        }
      },
      generarExcel () {
        this.itemsXls = this.dataConsulta.map(obj => {
          const rObj = {}
          rObj.id = obj.id
          rObj.nombre = obj.nombre
          rObj.mes = obj.mes
          rObj.ano = obj.ano
          rObj.sentencia = obj.sentencia
          rObj.conciliacion = obj.conciliacion
          rObj.auto = obj.auto
          rObj.demanda_improcedente = obj.demanda_improcedente
          rObj.apela_confirmada = obj.apela_confirmada
          rObj.apela_revocada = obj.apela_revocada
          rObj.apela_anulada = obj.apela_anulada
          return rObj
        })
        this.exportarExcel()
      },
      exportarExcel () {
        const XLSX = require('xlsx')
        const data = XLSX.utils.json_to_sheet(this.itemsXls)
        const workbook = XLSX.utils.book_new()
        const filename = 'plantilla_data_jud_lambayeque'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
        this.carga = false
      },
      validarZeroes (val) {
        if (val || val === 0) {
          return false
        }
        return true
      },
      updateProduccion (item, fin) {
        if (!item.id || !item.mes || !item.ano || !this.dataUsuario.sede || this.validarZeroes(item.sentencia) || this.validarZeroes(item.auto) || this.validarZeroes(item.conciliacion) || this.validarZeroes(item.demanda_improcedente) || this.validarZeroes(item.apela_confirmada) || this.validarZeroes(item.apela_revocada) || this.validarZeroes(item.apela_anulada)) {
          alert('La fila ' + item.id + ' no tiene todos los campos, por favor completar')
          this.incorrectos++
          if (this.incorrectos === parseInt(fin)) {
            alert('LAS ' + fin + ' FILAS DEBEN CORREGIRSE')
            this.carga = false
          }
        } else {
          const data = {
            id: item.id,
            mes: item.mes,
            ano: item.ano,
            sede: this.dataUsuario.sede,
            sentencia: item.sentencia,
            auto: item.auto,
            conciliacion: item.conciliacion,
            demanda_improcedente: item.demanda_improcedente,
            apela_confirmada: item.apela_confirmada,
            apela_revocada: item.apela_revocada,
            apela_anulada: item.apela_anulada,
            calidad_confirmada: 0,
            calidad_revocada: 0,
            calidad_anulada: 0,
            usuario: this.dataUsuario.id,
          }
          this.dataEnviar.push(data)
          this.sumaI++
          console.log(this.sumaI)
          if (this.sumaI === fin) {
            this.updateProduccionF()
          }
          /* axios
            .post(`${window.__env.dataCfg.urlApiData}estadistica/actualizar/`, data)
            .then((r) => {
              this.sumaI++
              console.log(this.sumaI) // eslint-disable-line
              console.log(this.incorrectos) // eslint-disable-line
              console.log(fin) // eslint-disable-line
              if (this.sumaI === (parseInt(fin) - this.incorrectos)) {
                this.carga = false
                alert('INFORMACIÓN ACTUALIZADA, HUBIERON ' + this.sumaI + ' FILAS SUBIDAS')
                this.archivoCsv2 = []
                this.incorrectos = 0
                this.sumaI = 0
              }
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
            }) */
        }
      },
      updateProduccionF () {
        axios
          .post(`${window.__env.dataCfg.urlApiData}estadistica/actualizar_bloque/`, this.dataEnviar)
          .then((r) => {
            this.carga = false
            this.textoRespuesta = 'FINAL'
            this.dataEnviar = []
            this.sumaI = 0
            r.data.resp === true ? alert('ACTUALIZADO, ' + r.data.nro_filas + ' FILAS') : alert('ERROR')
          })
          .catch((err) => {
            alert('Servicio no disponible' + err)
          })
      },
      updateResumen (e) {
        var reader = new FileReader()
        var arrayJuzgados = []
        var self = this
        reader.onload = function (e) {
          var data = new Uint8Array(e.target.result)
          var workbook = XLSX.read(data, { type: 'array' })
          const hoja1 = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[hoja1]
          arrayJuzgados = XLSX.utils.sheet_to_json(worksheet)
          for (var i = 0; i < arrayJuzgados.length; i++) {
            self.carga = true
            self.updateProduccion(arrayJuzgados[i], arrayJuzgados.length)
          }
        }
        reader.readAsArrayBuffer(e)
      },
    },
  }
</script>
